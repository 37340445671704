import React from 'react';

class Footer extends React.Component {

    render() {
        return (
            <div className="footer-green">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <div className="row">
                                <div className="col-md-6 col-xs-12 logo-f">
                                    <img src="https://web.dev/images/lockup.svg" alt="Câu lạc bộ đá bóng" title="câu lạc bộ bóng đá" />

                                </div>
                                <div className="col-md-6 col-xs-12 seo-text-f">
                                    chuyên trang tin tức nhanh về bóng đá được nhiều người hâm mộ quan tâm nhất nhì hiện nay!

                                </div>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 col-xs-12">
                            <h4>Follow Us</h4>
                            <ul className="social-network social-circle">
                                <li><a href="#" className="icoFacebook" title="Facebook">
                                    <svg height="20px" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-facebook-f fa-w-10 fa-3x"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" className=""></path></svg>
                                </a></li>
                                <li><a href="#" className="icoLinkedin" title="Linkedin">
                                    <svg height="20px" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa fa-youtube fa-w-18 fa-3x"><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" className=""></path></svg></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;