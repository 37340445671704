import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "gatsby"

class Header extends React.Component {

    render() {
        return (
            <nav className="nav-v4 navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <Link className="navbar-brand header__logo" to="/">
                    <img className="web-header__logo" src="https://web.dev/images/lockup.svg" alt="web.dev" />
                </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                            <li className="nav-item active">
                                <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link disabled" href="#">Disabled</a>
                            </li>
                        </ul>
                    </div>
            </nav>
                )
            }
        }
        
export default Header;