import React, { Fragment } from "react"
import { Link } from "gatsby"

import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./Header";
import Footer from "./Footer";
import { Container, Row, Col } from 'react-bootstrap';
import "./Layout.css";

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            padding: 10
          }}
          title={title}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <span
          style={{
            padding: 10
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </span>
      )
    }
    return (
      <Fragment>
        <Header tagName={header} />
        {/* <header>{header}</header> */}
        <div className="w-grid main-wrapper">
            {children}
        </div>
        <Footer />
        {/* <Container>
          <footer>
            © {new Date().getFullYear()}
            {` `}
          </footer>
        </Container> */}
      </Fragment>
    )
  }
}

export default Layout
